import React, { useState, useEffect } from 'react';
import { Center } from 'native-base';
import { Loader } from '@aws-amplify/ui-react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import Instructions from '../components/Instructions';
import {
  callCreateSessionLambda,
  fetchAnalysisResult,
  leevoApi,
} from '../services/api';
import languages from '../internationalization';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from './Success';
import Fail from './Fail';

const Liveness = () => {
  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const language = 'pt';

  useEffect(() => {
    handleStartLiveness();
  }, []);

  const handleStartLiveness = async () => {
    await handleGetToken();
    handleFetchCreateLiveness();
  };

  const handleGetToken = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    leevoApi.defaults.headers.Authorization = `Bearer ${token}`;
  };

  const handleFetchCreateLiveness = async () => {
    setLoading(true);
    const result = await callCreateSessionLambda();
    setSessionId(result?.session_id);
    setLoading(false);
  };

  const handleAnalysisComplete = async (sessionId) => {
    const result = await fetchAnalysisResult(sessionId);

    (result?.Confidence && result.Confidence) >= 70
      ? handleSuccess()
      : handleFail();
  };

  const handleSuccess = () => {
    toast.success('Validação realizada com sucesso!');
    setIsSuccess(true);
  };
  const handleFail = () => {
    toast.error('Não foi possível finalizar a validação!');
    setIsFail(true);
  };

  if (isSuccess) return <Success />;
  if (isFail) return <Fail />;
  if (loading)
    return (
      <Center height='100vh'>
        <Loader size='large' />
      </Center>
    );

  return (
    <FaceLivenessDetector
      sessionId={sessionId}
      region='us-east-1'
      onAnalysisComplete={() => handleAnalysisComplete(sessionId)}
      displayText={languages[language]}
      components={{
        Header: () => <></>,
        Instructions: Instructions,
      }}
      onUserCancel={handleFetchCreateLiveness}
    />
  );
};

export default Liveness;

const dictionary = {
  en: null,
  pt: {
    hintMoveFaceFrontOfCameraText: "Mova o rosto para frente da câmera",
    hintTooManyFacesText: "Muitos rostos detectados",
    hintFaceDetectedText: "Rosto detectado",
    hintCanNotIdentifyText: "Não é possível identificar o rosto",
    hintTooCloseText: "Muito próximo",
    hintTooFarText: "Muito distante",
    hintHoldFacePositionCountdownText: "Mantenha a posição do rosto",
    hintConnectingText: "Conectando...",
    hintVerifyingText: "Verificando...",
    hintIlluminationTooBrightText: "Iluminação muito brilhante",
    hintIlluminationTooDarkText: "Iluminação muito escura",
    hintIlluminationNormalText: "Iluminação normal",
    hintHoldFaceForFreshnessText: "Mantenha o rosto",

    cameraMinSpecificationsHeadingText: "Especificações Mínimas da Câmera",
    cameraMinSpecificationsMessageText:
      "Sua câmera não atende às especificações mínimas necessárias para esta função.",
    cameraNotFoundHeadingText: "Câmera não Encontrada",
    cameraNotFoundMessageText:
      "Não foi possível encontrar uma câmera disponível. Verifique se sua câmera está corretamente conectada e configurada.",
    retryCameraPermissionsText: "Requisitar Novamente Permissões da Câmera",

    instructionsBeginCheckText: "Iniciar teste",
    photosensitivyWarningHeadingText: "Atenção: Fotossensibilidade",
    photosensitivyWarningBodyText:
      "Pode conter luzes piscantes. Cuidado se você tem epilepsia fotossensível.",
    photosensitivyWarningInfoText: "Aviso de Fotossensibilidade",

    recordingIndicatorText: "Gravando...",
    cancelLivenessCheckText: "Cancelar verificação de autenticidade",

    timeoutHeaderText: "Tempo excedido",
    timeoutMessageText:
      "Desculpe, o tempo limite de 7 segundos foi excedido. Por favor, tente novamente.",
    faceDistanceHeaderText: "Distância do Rosto",
    faceDistanceMessageText: "Mantenha distância do rosto para a câmera.",
    clientHeaderText: "Erro",
    clientMessageText: "Ocorreu um erro. Por favor, tente novamente.",
    serverHeaderText: "Erro do Servidor",
    serverMessageText:
      "Ocorreu um erro no servidor. Por favor, tente novamente.",
    landscapeHeaderText: "Orientação Paisagem",
    landscapeMessageText:
      "Por favor, gire seu dispositivo para o modo paisagem.",
    portraitMessageText: "Orientação Retrato",
    tryAgainText: "Tentar Novamente",
    tryAgainMessageText: "Tentar Novamente",
  },
};

export default dictionary;

import axios from "axios";
import { toast } from "react-toastify";

const leevoApi = axios.create({
  // TODO: Add .env
  // baseURL: "https://panel.oragoapp.com.br/api/v1/liveness",
  baseURL: "https://panel-staging.oragoapp.com.br/api/v1/liveness",
  //baseURL: "http://localhost/api/v1/liveness",
  headers: {
    Accept: "application/json",
    Authorization: "Bearer 7859dd98-8c80-43be-b200-b36c4d3ab1ba"
  },
});

const callCreateSessionLambda = async () => {
  try {
    const response = await leevoApi.get("/get-session");
    return response.data;
  } catch (error) {
    toast.error(
      typeof error?.message === "string"
        ? error?.message
        : "Oops! Ocorreu um erro no sistema"
    );
  }
};

const fetchAnalysisResult = async (sessionId) => {
  try {
    const response = await leevoApi.get(`/${sessionId}/result`);
    return response.data;
  } catch (error) {
    toast.error(
      typeof error?.message === "string"
        ? error?.message
        : "Oops! Ocorreu um erro no sistema"
    );
  }
};

export { callCreateSessionLambda, fetchAnalysisResult, leevoApi };

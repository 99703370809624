import React from "react";
import { MdErrorOutline } from "react-icons/md";

import { Center, Text, VStack, Box, Button } from "native-base";

const Fail = () => {
  const handleRefreshPage = () => {
    window.location.reload();
  };

  return (
    <Box height="100vh" backgroundColor="error.400">
      <VStack height="full" justifyContent="center">
        <Center marginY={5}>
          <MdErrorOutline color="white" size={150} />
        </Center>
        <Text fontWeight="bold" fontSize="3xl" textAlign="center" color="white">
          Falha.
        </Text>
        <Text
          fontSize="l"
          fontWeight="bold"
          textAlign="center"
          color="white"
          paddingX={16}
        >
          Não foi possível confirmar sua identidade com confiança suficiente.
          Por favor, tente novamente.
        </Text>
        <VStack mt="8" alignItems="center">
          <Button
            onPress={handleRefreshPage}
            size="lg"
            variant="subtle"
            bgColor="white"
            _text={{
              color: "error.500",
            }}
          >
            Tentar novamente
          </Button>
        </VStack>
        ;
      </VStack>
    </Box>
  );
};

export default Fail;

import React from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { NativeBaseProvider, Box } from "native-base";
import { ThemeProvider as AwsThemeProvider } from "@aws-amplify/ui-react";
import awsexports from "./config/aws-exports";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Liveness from "./screens/Liveness";

Amplify.configure(awsexports);

const App = () => {
  return (
    <NativeBaseProvider>
      <AwsThemeProvider>
        <Box backgroundColor="#f8f8fc" height="100vh">
          <Liveness />
          <ToastContainer
            position="top-center"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Box>
      </AwsThemeProvider>
    </NativeBaseProvider>
  );
};

export default App;

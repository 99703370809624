import React from "react";
import { View, Text } from "native-base";
import image from "../assets/liveness_example.jpg";
import imagePlaceholder from "../assets/liveness_example_tiny.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Instructions = () => {
  return (
    <View
      flex={1}
      borderColor="muted.300"
      alignItems="center"
      borderWidth="1"
      marginTop="10"
      marginBottom="5"
      backgroundColor="#fff"
    >
      <Text paddingTop={3} fontSize="3xl" textAlign="center">
        Centralize seu rosto
      </Text>
      <View margin={5}>
        <LazyLoadImage
          src={image}
          placeholderSrc={imagePlaceholder}
          width={200}
          height={300}
          alt=""
          style={{
            objectFit: "cover",
            borderRadius: "50%",
          }}
          effect="blur"
        />
      </View>
    </View>
  );
};

export default Instructions;

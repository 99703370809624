import React from "react";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";

import { Center, Text, VStack, Box } from "native-base";

const Success = () => {
  return (
    <Box height="100vh" backgroundColor="success.400">
      <VStack height="full" justifyContent="center">
        <Center marginY={5}>
          <IoCheckmarkDoneCircleSharp color="white" size={150} />
        </Center>
        <Text fontWeight="bold" fontSize="3xl" textAlign="center" color="white">
          Sucesso.
        </Text>
        <Text
          fontSize="l"
          fontWeight="bold"
          textAlign="center"
          color="white"
          paddingX={20}
        >
          Você está pronto para continuar o pagamento pelo aplicativo!
        </Text>
      </VStack>
    </Box>
  );
};

export default Success;
